





























































import { Component, Watch, Vue } from "vue-property-decorator";
import {
  dispatchDeleteTicket,
  dispatchGetTickets,
} from "@/store/admin/actions";
import { ITicket, ITicketGet } from "@/interfaces";
import { apiUrl } from "@/env";
import { readCityId } from "@/store/main/getters";

@Component
export default class CityTickets extends Vue {
  private totalTickets: number = 0;
  private loading: boolean = true;
  private options: { page: number; itemsPerPage: number } = {
    page: 1,
    itemsPerPage: 10,
  };
  private ticketsState: ITicket[] = [];

  private dialog: boolean = false;
  private ticketIdToDelete: number = -1;

  get ticketToDelete() {
    if (this.ticketIdToDelete == -1) {
      return null;
    }

    return this.ticketsState.filter(
      (ticket) => ticket.id == this.ticketIdToDelete
    )[0];
  }

  @Watch("options", { deep: true })
  private updateTicketState() {
    this.getDataFromApi();
  }

  public headers = [
    {
      text: "Bild",
      value: "image_filename",
      align: "left",
      sortable: false,
    },
    {
      text: "Titel",
      value: "title",
      align: "left",
      sortable: false,
    },
    {
      text: "Aktiv",
      value: "is_active",
      align: "left",
      sortable: false,
    },
    {
      text: "Aktionen",
      value: "id",
      sortable: false,
    },
  ];

  get apiUrl() {
    return apiUrl;
  }

  public editTicket(ticketId: number) {
    this.$router.push({
      name: "main-city-tickets-edit",
      params: { id: ticketId.toString() },
    });
  }

  public async deleteTicket(ticketId: number) {
    this.dialog = true;
    this.ticketIdToDelete = ticketId;
  }

  public async commitDeleteTicket() {
    await dispatchDeleteTicket(this.$store, {
      cityId: readCityId(this.$store),
      ticketId: this.ticketIdToDelete,
    });
    await this.getDataFromApi();
    this.dialog = false;
    this.ticketIdToDelete = -1;
  }

  public abortDeleteTicket() {
    this.dialog = false;
    this.ticketIdToDelete = -1;
  }

  private async getDataFromApi() {
    this.loading = true;

    const { page, itemsPerPage } = this.options;
    const skip = (page - 1) * itemsPerPage;
    const response: ITicketGet = await dispatchGetTickets(this.$store, {
      cityId: readCityId(this.$store),
      skip: skip,
      limit: itemsPerPage,
    });
    this.ticketsState = response.tickets;
    this.totalTickets = response.total;
    this.loading = false;
  }
}
