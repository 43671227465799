var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"light":""}},[_c('v-toolbar-title',[_vm._v("Mängelmelder")])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.ticketsState,"options":_vm.options,"server-items-length":_vm.totalTickets,"loading":_vm.loading,"footer-props":{
      itemsPerPageOptions: [5, 10, 15],
      itemsPerPageText: 'Zeilen pro Seite:',
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.image_filename",fn:function(ref){
    var item = ref.item;
return [(item.image_filename)?_c('v-img',{staticClass:"my-4",attrs:{"src":_vm.apiUrl + '/api/v1' + item.image_filename,"height":"150","width":"150"}}):_c('v-icon',{staticClass:"my-4",attrs:{"size":"150"}},[_vm._v(" mdi-image-off-outline ")])]}},{key:"item.is_active",fn:function(ref){
    var item = ref.item;
return [(item.is_active)?_c('v-icon',[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.id",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.editTicket(item.id)}}},[_vm._v(" mdi-pencil-outline ")]),_c('v-icon',{on:{"click":function($event){return _vm.deleteTicket(item.id)}}},[_vm._v(" mdi-delete-outline ")])]}}])}),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.ticketIdToDelete != -1)?_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Mängelmeldung Löschen")])]),_c('v-card-text',[_vm._v(" Sind Sie sicher das Sie die Mängelmeldung "+_vm._s(_vm.ticketToDelete.title)+" löschen möchten? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.abortDeleteTicket}},[_vm._v(" Abbrechen ")]),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.commitDeleteTicket}},[_vm._v(" Löschen ")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }